<template>
  <div>
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in clips"
          :key="item.id"
          md="5"
          lg="4"
          xl="3"
        >
          <router-link :to="'/vod/'+item.id">
            <b-card>
              <img :src="'https://clips.brimecdn.com/jakenbake/vods/thumbnails/'+item.id+'.jpg'">
              <span
                id="title"
                style="color: #7367f0; font-weight: bold;"
              >
                {{ item.title }}
              </span>
              <div
                style="color: #b8c2cc; font-size: 13px"
                class="clipDescription"
              >
                <span><span style="font-weight: bold;" /></span>
                <span>{{ formatDate(item.created_at) }}</span>
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </section>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="50"
      use-router
      class="mb-0"
      align="center"
    />
    <b-modal
      id="modal-center"
      ref="clipPlayerModal"
      size="lg"
      centered
      :title="currentClip.title"
      ok-only
      ok-title="Accept"
      hide-footer
      @hide="modalClose"
    >
      <vue-plyr
        id="plyr"
        ref="plyr"
        :options="options"
      >
        <video />
      </vue-plyr>
      <div
        style="color: #b8c2cc; font-size: 14px"
        class="clipDescriptionModal"
      >
        <span><span style="font-weight: bold;">Clipper:</span> {{ currentClip.creator_name }}</span>
        <span style="font-weight: bold;">{{ formatDate(currentClip.created_at) }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPaginationNav, BCard, VBModal, BModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BPaginationNav,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      clips: [],
      currentClip: '',
      options: { controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'download', 'fullscreen'] },
    }
  },
  watch: {
    async $route(to) {
      console.log(to)
      let year = '2021'
      if (to.query.year) {
        year = to.query.year
      }
      const clipsQuery = await axios.get(`https://archive-api.brimecdn.com/ords/admin/api/vods?q={"$orderby":{"created_at":"desc"}}&offset=${to.query.page * 25}`)
      this.clips = clipsQuery.data.items
    },
  },
  async created() {
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search))
    if (!urlParams.page) {
      urlParams.page = 1
    }
    if (urlParams.year) {
      const { year } = urlParams
      const clipsQuery = await axios.get('https://archive-api.brimecdn.com/ords/admin/api/vods?q={"$orderby":{"created_at":"desc"}}')
      this.clips = clipsQuery.data.items
    } else {
      const clipsQuery = await axios.get('https://archive-api.brimecdn.com/ords/admin/api/vods?q={"$orderby":{"created_at":"desc"}}')
      this.clips = clipsQuery.data.items
    }
  },
  methods: {
    modalClose() {
      const videoElement = document.getElementById(this.currentClip.id)
      videoElement.pause()
      videoElement.removeAttribute('src') // empty source
      videoElement.load()
      this.$refs[this.currentClip.id].$destroy()
    },
    linkGen(pageNum) {
      const urlParams = Object.fromEntries(new URLSearchParams(window.location.search))
      let year = '2021'
      if (urlParams.year) {
        year = urlParams.year
        return pageNum === 1 ? '?' : `?year=${year}&page=${pageNum}`
      }
      return pageNum === 1 ? '?' : `?page=${pageNum}`
      // this.clips = []
      // const urlParams = Object.fromEntries(new URLSearchParams(window.location.search))
      // if (urlParams.year) {
      //   const { year } = urlParams
      //   const clipsQuery = await axios.get(`https://archive-api.brimecdn.com/ords/admin/api/clips?q={"created_at":{"$like":"${year}%"},"$orderby":{"created_at":"asc"}}&offset=${pageNum * 25}`)
      //   this.clips = clipsQuery.data.items
      //   window.scrollTo(0, 0)
      //   const result = pageNum === 1 ? '?' : `?page=${pageNum}&year=${year}`
      //   console.log(result)
      //   return pageNum === 1 ? '?' : `?page=${pageNum}&year=${year}`
      // } else {
      //   const clipsQuery = await axios.get(`https://archive-api.brimecdn.com/ords/admin/api/clips?q={"$orderby":{"created_at":"desc"}}&offset=${pageNum * 25}`)
      //   this.clips = clipsQuery.data.items
      //   window.scrollTo(0, 0)
      //   const result = pageNum === 1 ? '?' : `?page=${pageNum}&year=${year}`
      //   console.log(result)
      //   return pageNum === 1 ? '?' : `?page=${pageNum}`
      // }
      // const clipsQuery = await axios.get(`https://archive-api.brimecdn.com/ords/admin/api/clips?q={"$orderby":{"created_at":"desc"}}&offset=${pageNum * 25}`)
    },
    formatDate(date) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
      }
      return new Date(date).toLocaleDateString(undefined, options)
    },
    getYear(date) {
      return new Date(date).getFullYear()
    },
  },
}
</script>

<style>
@media (min-width: 992px) {
.modal-lg{
max-width: 1100px;
}
}
.modal-backdrop{
background-color: #000000;
opacity: 0.9;
}
.modal-body{
padding: unset;
}
.modal-content{
 box-shadow: 0px 0px 20px rgba(115,103,240,1) !important;
}
.clipDescription{
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.clipDescriptionModal{
    display: flex;
    justify-content: space-between;
    margin: 0.8rem;
}
#title {
  color: #7367f0;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
 box-shadow: 0px 5px 10px 0 rgb(12 20 39 / 48%);
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}
#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}
.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
.live__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  background: rgba(252, 53, 55, 0.75);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hd__pill {
  z-index: 0;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  background: linear-gradient(45deg, rgba(132,57,175,1) 0%, rgba(252,53,55,1) 100%);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fps__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fps__pill {
    background-image: linear-gradient(178deg, #fc3537, #8439af);
    background-size: 400% 400%;
    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}
@-webkit-keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
@keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
.viewcount__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  right: 23px;
  top: 20px;
  padding: 2px 6px;
  background: rgba(0,0,0,0.75);
  background-filter: blur(10px);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}
.card {
  height: calc(100% - 3rem);
  display: flex;
  background-color: unset;
}
.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: 2px;
  text-align: left;
  display: block;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
}
#stream {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  height: 100%;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 2px;
}
</style>
